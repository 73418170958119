import React from "react";

const personalInfoContent = [
  { meta: "first name", metaInfo: "Andy" },
  { meta: "last name", metaInfo: "Yu" },
  { meta: "Age", metaInfo: "29 Years" },
  { meta: "Hybrid(Onsite/Remote)", metaInfo: "Available" },
  { meta: "Remote", metaInfo: "Available" },
  { meta: "Location", metaInfo: "Houston, TX" },
  { meta: "phone", metaInfo: "+13475362865" },
  { meta: "Email", metaInfo: "andyyuthehuman@gmail.com" },
  { meta: "Slack", metaInfo: "MemberID: U02A7MMFDNZ" },
  { meta: "langages", metaInfo: "English, Chinese" },
];

const PersonalInfo = () => {
  return (
    <ul className="about-list list-unstyled open-sans-font">
      {personalInfoContent.map((val, i) => (
        <li key={i}>
          <span className="title">{val.meta}: </span>
          <span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
            {val.metaInfo}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default PersonalInfo;
