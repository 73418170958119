import React from "react";

const skillsContent = [
  { skillIcon: "img/icons/PYTHON.png" ,skillName: "PYTHON"},
  { skillIcon: "img/icons/HTML.png" ,skillName: "HTML" },
  { skillIcon: "img/icons/JAVASCRIPT.png" ,skillName: "JAVASCRIPT" },
  { skillIcon: "img/icons/CSS.png" ,skillName: "CSS" },
  { skillIcon: "img/icons/REACT.png" ,skillName: "REACT" },
  { skillIcon: "img/icons/RUBY.png" ,skillName: "Ruby" },
  { skillIcon: "img/icons/AWS.png" ,skillName: "AWS" },
  { skillIcon: "img/icons/WEBPACK.png" ,skillName: "WEBPACK" },
  { skillIcon: "img/icons/POSTGRESQL.png" ,skillName: "POSTGRESQL"},
  { skillIcon: "img/icons/MONGODB.png" ,skillName: "MONGODB"},
  { skillIcon: "img/icons/NODEJS.png" ,skillName: "NODEJS"},
];

const Skills = () => {
  return (
    <>
      {skillsContent.map((val, i) => (
        <div className="col-6 col-md-3 mb-3 mb-sm-5" key={i}>
          <div className="img text-center">
            <img src={val.skillIcon} alt={val.skillName}/>
          </div>
          <h6 className="text-uppercase open-sans-font text-center">
            {val.skillName}
          </h6>
        </div>
      ))}
    </>
  );
};

export default Skills;
