import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./assets/scss/main.scss";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD-pLRPXmH12X2DZsxDvoHLL8RBH2BOhxU",
  authDomain: "andy-yu-portfolio.firebaseapp.com",
  projectId: "andy-yu-portfolio",
  storageBucket: "andy-yu-portfolio.appspot.com",
  messagingSenderId: "216623567227",
  appId: "1:216623567227:web:d5a2f694a158eeae4e2dde",
  measurementId: "G-GZ1B6PF7CZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
